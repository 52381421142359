import ModalAttrBinding from './bindings/ModalAttrBinding';
import ScrollToBinding from './bindings/ScrollToBinding';
import DropdownBinding from './bindings/DropdownBinding';
import TooltipBinding from './bindings/TooltipBinding';
import PasswordToggleBinding from './bindings/PasswordToggleBinding';
import ElementInViewBinding from './bindings/ElementInViewBinding';
import {yieldThread} from './utils';


export abstract class Integration {
    constructor() {
        this.setupSentry();
        this.setupGlobalModules();
        this.setupModels();
        this.setupServices();
        this.configureServices();
        yieldThread().then(() => {
            this.setupApps();
        })
    }
    setupSentry(): void {};
    configureServices(): void {};
    setupGlobalModules(): void {
        ModalAttrBinding();
        ScrollToBinding();
        DropdownBinding();
        TooltipBinding();
        PasswordToggleBinding();
        ElementInViewBinding();
    };

    abstract setupModels(): void;
    abstract setupServices(): void;
    abstract setupApps(): void;
}
