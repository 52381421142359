import {GenericDropdownController} from '../GenericDropdown/GenericDropdown';
import IVueComponent from '../../adapters/IVueComponent';
import {html} from './FontDropdown.html';

export default function FontDropdown(): IVueComponent {
    return {
        controller: GenericDropdownController,
        tag: 'font-dropdown',
        template: html
    }
}