import APIResponse from '../../../core/models/APIResponse';
import {MetricOptions, User} from '../models/User';
import {Http} from '../../../core/services/Http';
import {EventDispatcher} from 'simple-ts-event-dispatcher';
import {Services} from '../../../core/services/Services';
import CookieManager from '../../../core/services/CookieManager';

export default class UserProfileService extends EventDispatcher {
    public user: User;
    private users: APIResponse<User>;
    public login_redirect;

    public metric_option: MetricOptions;

    constructor() {
        super();

        let user_element = document.getElementById("user-data")
        if (user_element && user_element.textContent) {
            let data = JSON.parse(atob(user_element.textContent.replace(/(\r\n\t|\n|\r\t|\s)/gm, '')));
            if (data) {
                this.user = new (Services.get<typeof User>('User'))(data);
                this.metric_option = this.user.metric_option;
            }
        }

        // Support for guests
        if (!this.metric_option) {
            let option = Services.get<typeof CookieManager>('$cookies').get('measurement');
            if (option == MetricOptions.IMPERIAL || option == MetricOptions.METRIC) {
                this.metric_option = option;
            }
        }

        if (!this.metric_option) {
            this.metric_option = MetricOptions.IMPERIAL;
        }
    }

    loggedIn(): boolean {
        return !!this.user
    }

    updateSubscriptionPreferences(subscribe: boolean) {
        this.user.is_marketing_eligible = subscribe;
        Services.get<Http>('$http').request({
            url: '/profile/api/subscription-update/',
            method: 'POST',
            data: {
                marketing: subscribe
            }
        }).then((response) => {
            this.user.reload();
        }, (response) => {
            this.user.reload();

            return response;
        });
    }

    updateMetricOption() {
        if (this.user) {
            Services.get<Http>('$http').request({
                url: '/profile/api/metric-option-update/',
                method: 'POST',
                data: {
                    option: this.metric_option
                }
            })
        }
        else {
            Services.get<typeof CookieManager>('$cookies').set('measurement', this.metric_option);
        }

        this.trigger('sync');
    }

    get conversion_ratio() {
        return this.metric_option == MetricOptions.METRIC ? 2.54 : 1
    }

    formatMeasurement(inches) {
        return `${this.convertSizeFromInches(inches)}${this.size_notation}`
    }

    convertSizeFromInches(inches) {
        return inches * this.conversion_ratio;
    }

    convertSizeToInches(inches) {
        return inches / this.conversion_ratio;
    }

    metric() {
        return this.metric_option == MetricOptions.METRIC;
    }

    get size_label() {
        if(this.metric_option == MetricOptions.METRIC) {
            return 'cm'
        }
        else {
            return 'inch'
        }
    }

    get size_notation() {
        if(this.metric_option == MetricOptions.METRIC) {
            return 'cm'
        }
        else {
            return '"'
        }
    }
}
