import VueApp from '../../adapters/VueApp';
import {method} from '../../adapters/VueComponent';

export default class BackendAlertApp extends VueApp {

    override mounted() {
        super.mounted();
    }

    @method()
    removeAlert() {
        document.querySelector('#backend-alerts-app')?.remove();
    }
}