import VueMixin from '../adapters/VueMixin';
import {mobileScreenSize, xsScreenSize} from '../utils/utils';
import {data} from '../adapters/VueComponent';

export default class MobileMixin extends VueMixin {
    /*
        Used for detecting if the client is on mobile or desktop screen size
     */

    @data()
    mobile: boolean;

    @data()
    screen_xs: boolean;

    protected mobileCheck;

    constructor(component) {
        super(component);
        this.mobileCheck = () => {
            this.mobile = mobileScreenSize();
            this.screen_xs = xsScreenSize();
        }
        this.mobileCheck();
    }

    override unmounted() {
        super.unmounted();
        window.removeEventListener('resize', this.mobileCheck);
    }

    override mounted() {
        super.mounted();
        window.addEventListener('resize', this.mobileCheck, {passive: true});
    }

}