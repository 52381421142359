import {html} from './CallbackPaginator.html';
import IVueComponent from '../../adapters/IVueComponent';
import VueComponent, {computed, method, prop} from '../../adapters/VueComponent';

export class CallbackPaginatorController extends VueComponent {
    @prop({
        emit: true
    })
    page;

    @prop()
    total;

    @prop()
    limit;

    @prop()
    callback;

    @prop()
    small: boolean;

    constructor(c) {
        super(c);
    }

    @computed()
    totalPages() {
        return Math.ceil(Number(this.total) / Number(this.limit));
    }

    @method()
    changePage(pageTo) {
        this.page = pageTo;
        if (this.callback) {
            this.callback(pageTo);
        }
        else {
            throw new Error('Callback Required')
        }
    }

    @computed()
    pages() {
        let pages = []

        let items = this.small ? 2 : 3;

        let start = Math.max(Number(this.page) - items, 1)
        let end = Math.min(Number(this.page) + items, this.totalPages());

        for (let i = start; i <= end; i++) {
            pages.push(i);
        }
        return pages;
    }
}

export default function CallbackPaginator(): IVueComponent {
    return {
        controller: CallbackPaginatorController,
        template: html,
        tag: 'callback-paginator'
    };
}
