import VueComponent, {data, method} from './VueComponent';
import {closeModal, openModal} from '../utils/Modal';
import {uuid4} from '../utils/uuid';


export abstract class ModalController extends VueComponent {
    private observer: MutationObserver;
    private modal_element;
    protected dynamic_id: boolean;

    static uuids: string[] = [];

    @data()
    modal_id: string;

    @data()
    modal_uuid: string;

    @data()
    isOpen: boolean;

    constructor(component, modal_id) {
        super(component);

        if (!modal_id) {
            console.error(`The modal ID for ${this.constructor.name} was not defined.`);
        }

        this.modal_id = modal_id;
        this.generate_modal_id();
    }

    private generate_modal_id(max=5) {
        /*
            If multiple modals are used on a page there can be id collisions when using <label for="some-id"> so an internal id is generated to help make ids unique.
         */
        let uuid = uuid4().slice(0, max);

        if (ModalController.uuids.indexOf(uuid) != -1) {
            return this.generate_modal_id(max + 1);
        }

        this.modal_uuid = uuid;
        ModalController.uuids.push(this.modal_uuid);
    }

    override mounted() {
        super.mounted();

        this.modal_element = this.$el;

        // If we have anything other than a normal element node, use the parent and query from that
        if (this.modal_element.nodeType != Node.ELEMENT_NODE && this.modal_element.parentNode) {
            this.modal_element = this.modal_element.parentNode;
        }

        if (!this.dynamic_id && this.modal_element.id != this.modal_id) {
            if (this.modal_element.querySelector) {
                let qry = this.modal_element.querySelector(`#${this.modal_id}`);

                if (qry && qry.id == this.modal_id) {
                    this.modal_element = qry;
                }
                else {
                    console.error(`The modal id ${this.modal_id} is not the root element or child of the root element ${this.constructor.name}. The id "${this.modal_element.id}" was found instead.`);
                }
            }
            else {
                console.error(`The modal id ${this.modal_id} is not the root element of ${this.constructor.name}. The id "${this.modal_element.id}" was found instead.`);
            }
        }

        // This will watch for changes to the class list and determine if onOpen or onClose needs to be called
        this.observer = new MutationObserver((mutations, observer) => {
            let class_change = false;
            for (const mutation of mutations) {
                if (mutation.attributeName == 'class') {
                    class_change = true;
                    break;
                }
            }

            if (class_change) {
                if (this.modal_element.classList.contains('show') && !this.isOpen) {
                    this.onOpen();
                    this.isOpen = true;
                }
                else if (!this.modal_element.classList.contains('show') && this.isOpen) {
                    this.onClose();
                    this.isOpen = false;
                }
            }
        });
        this.observer.observe(this.modal_element, {attributes: true});
    }

    // Called when the modal is closed
    protected reset(): void {}

    // Called when the modal is opened
    protected onOpen(): void {}

    // Called when the modal is closed
    protected onClose(): void {}

    @method()
    protected open(): void {
        if (!this.isOpen) {
            openModal(this.modal_id);
        }
    }

    @method()
    protected close(): void {
        if (this.isOpen) {
            closeModal(this.modal_id);
        }
    }
}
