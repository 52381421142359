import {html} from './ChatHeader.html';
import VueComponent, {data, prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {Services} from '../../../../core/services/Services';
import Company from '../../../../core/services/Company';

export class ChatHeaderController extends VueComponent {
    public chat_input: string;
    public element;

    @prop()
    chat: any;

    @data()
    company: any;

    constructor(component, Company) {
        super(component);

        this.company = Services.get<Company>('Company')
    }
}

export default function ChatHeader(): IVueComponent {

    return {
        controller: ChatHeaderController,
        template: html,
        tag: 'chat-header'
    };
}
