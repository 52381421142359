import SocketEventHandler, {SocketEvent} from '../../../core/services/SocketEventHandler';
import {ChatLine} from '../models/ChatLine';
import {ChatEvent} from '../models/ChatEvent';
import {ChatFile} from '../models/ChatFile';
import {ChatItemAbstract} from '../models/ChatItemAbstract';
import {ChatRoom} from '../models/ChatRoom';
import MessageList from '../../../core/utils/MessageList';
import {Http} from '../../../core/services/Http';
import {Services} from '../../../core/services/Services';
import CookieManager from '../../../core/services/CookieManager';
import UserProfileService from '../../userprofile/services/UserProfileService';

export enum ChatState {
    LOADING,
    COLLECTING_INFO,
    WAITING_FOR_REP,
    OUT_OF_OFFICE,
    CLOSING,
    WAITING_TIMEOUT,
    DISCONNECTED,
    RECONNECTING,
    IN_CHAT,
    CHAT_CLOSED,
    OFFLINE_MESSAGE_SENT,
}

export default abstract class BaseChatService extends SocketEventHandler {
    protected abstract override socket_url: string;

    // History
    public chat_lines: ChatLine[];
    public chat_events: ChatEvent[];
    public chat_files: ChatFile[];
    public history: ChatItemAbstract[];
    protected _chat_room: ChatRoom;

    public out_of_office: boolean;
    public leaving_message: boolean;
    public attempting_to_close: boolean;
    public has_waited_too_long: boolean;
    public ok_waiting: boolean;
    public offline_message_sent: boolean;

    // Upload File
    private file: any;
    public in_upload_state: boolean;

    // Typing signal
    private _chat_input: string;
    private last_typing_update: Date;
    public users_typing: any[];

    // Other states
    public opened: boolean;
    public _minimized: boolean;

    private wait_timeout: any;
    private max_wait_time = 1000 * 60 * 5;

    protected error_list: MessageList;
    public upload_percent: number;

    private static _new_message = null;
    static get new_message() {
        if (!this._new_message) {
            this._new_message = new Audio('/static/audio/new-message.mp3');
        }
        return this._new_message;
    }

    private static _new_chat = null;
    static get new_chat() {
        if (!this._new_chat) {
            this._new_chat = new Audio('/static/audio/new-chat.mp3');
        }
        return this._new_chat;
    }

    get minimized() {
        return this._minimized;
    }

    set minimized(v) {
        this._minimized = v;

        Services.get<typeof CookieManager>('$cookies').set('chat_active', !this._minimized);
    }

    get chat_room() {
        return this._chat_room;
    }
    set chat_room(v) {
        this._chat_room = v;
        this._chat_room?.bind('sync', () => {
            this.trigger('sync');
        })
    }

    setupFile(file) {
        this.file = file;
        return this.uploadFile();
    }

    inOverlayState() {
        return this.in_upload_state;
    }


    uploadFile() {
        let formData = new FormData();
        formData.append('file', this.file);
        formData.append('chat_room', String(this.chat_room.id));
        return Services.get<Http>('$http').request(
            {
                url: '/chat/api/upload-file/',
                data: formData,
                method: 'POST',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.total) {
                        this.upload_percent = (progressEvent.loaded / progressEvent.total) * 100;
                    }
                    else {
                        this.upload_percent = 0;
                    }
                }
            }
        ).then((response) => {
            this.file = null;
            this.in_upload_state = false;
        }, (response) => {
            this.file = null;
            this.in_upload_state = false;
            return response
        });
    }

    updateChatRoom(customer_name, email, description) {
        this.error_list = new MessageList();
        if (!customer_name || customer_name.trim() == '') {
            this.error_list.add('customer-name', 'This field is required.');
        }
        if (!email || email.trim() == '') {
            this.error_list.add('email', 'This field is required.');
        }
        if (!description || description.trim() == '') {
            this.error_list.add('description', 'This field is required.')
        }
        if (this.error_list.length != 0) {
            return;
        }

        this.chat_room.description = description;
        this.sendEvent('chat.set.description', {
            'description': this.chat_room.description,
            'customer_name': customer_name,
            'email': email,
            'screen_size': `${screen.width}x${screen.height}`
        });

        if (this.out_of_office) {
            this.leave_chat();
            return;
        }

        this.has_waited_too_long = false;
        if (this.wait_timeout) {
            clearTimeout(this.wait_timeout);
        }

        this.wait_timeout = setTimeout(() => {
            this.has_waited_too_long = true;
        }, this.max_wait_time); // 5 minutes
    }

    protected override onSocketOpen() {
        super.onSocketOpen();
        this.reset();
    }

    protected override onSocketClose(e) {
        super.onSocketClose(e);
        this.reset();
    }

    reset() {
        this.chat_lines = [];
        this.chat_events = [];
        this.chat_files = [];
        this.history = [];
        this.users_typing = [];
        this.out_of_office = false;
        this.leaving_message = false;
        this.attempting_to_close = false;
        this.has_waited_too_long = false;
        this.ok_waiting = false;
        this.offline_message_sent = false;
        this.file = null;
        this.in_upload_state = false;

        if (this.wait_timeout) {
            clearTimeout(this.wait_timeout);
            this.wait_timeout = null;
        }
    }

    bindEvents() {
        this.bind('chat.display', this.display.bind(this));
        this.bind('chat.update_history', this.update_history.bind(this));
        this.bind('chat.event', this.chat_event.bind(this));
        this.bind('chat.is_typing', this.person_is_typing.bind(this));
        this.bind('chat.closed', () => {this.chat_room.closed = true});
        this.bind('chat.offline', () => {this.out_of_office = true;});
        this.bind('chat.uploaded-file', this.display_file.bind(this))
    }

    public update_chat_room(chat_room) {
        this.chat_room = chat_room;
        this.chat_room.enableSync(null);

        this.chat_room.bind('sync-reload', () => {
            this.trigger('sync');
        })

        this.trigger('sync');
    }

    protected update_history(event: SocketEvent) {
        this.update_chat_room(event.modelInstance<ChatRoom>('ChatRoom'));

        const chat_history = event.modelSet<ChatLine>('ChatLine');
        for (const item of chat_history.items) {
            this.chat_lines.push(item);
            this.history.push(item);
        }

        const event_history = event.modelSet<ChatEvent>('ChatEvent');
        for (const item of event_history.items) {
            this.chat_events.push(item);
            this.history.push(item);
        }

        const chat_files = event.modelSet<ChatFile>('ChatFile');
        for (const item of chat_files.items) {
            this.chat_files.push(item);
            this.history.push(item);
        }

        this.sort();
        this.trigger('initialized');
    }

    protected display(event: SocketEvent) {
        const history = event.modelInstance<ChatLine>('ChatLine');
        this.chat_lines.push(history);
        this.history.push(history);

        if (!this.isSelf(history)) {
            BaseChatService.play_sound(history, 'new-message');
        }

        this.chat_room.new_messages += 1;
        this.chat_room.trigger('new-message');

        this.clear_typing_events(history.user_identifier);
        this.sort();
    }

    public static play_sound(item, element) {
        // We only want one sound event for all of the windows/tabs that are open so we need to use a shared
        // storage to see what has played. We only have 4096 bytes if the browser only supports basic cookies.
        // If its a modern browser we will have around 10mb but lets assume the worse and hope for the best.
        let played_sounds = Services.get<typeof CookieManager>('$cookies').getObject('sound-events') || [];

        // Remove old events, we don't have much memory to work with so lets keep it small
        let now = new Date();
        let update_sounds = [];
        for (let event of played_sounds) {
            // If the item is less than 10 seconds old, keep it in the list
            if (event.date + 5000 > now.getTime()) {
                update_sounds.push(event);
            }
        }

        played_sounds = update_sounds;

        let found = false;
        for (let event of played_sounds) {
            if (item.id == event.id && item.type == event.type) {
                found = true;
                break
            }
        }

        if (!found) {
            played_sounds.push({
                id: item.id,
                date: (new Date()).getTime(),
                type: item.type
            })

            if (element == 'new-message') {
                BaseChatService.new_message.play();
            }
            if (element == 'new-chat-sound') {
                BaseChatService.new_chat.play();
            }
        }

        Services.get<typeof CookieManager>('$cookies').putObject('sound-events', played_sounds);
    }

    protected display_file(event: SocketEvent) {
        const file = event.modelInstance<ChatFile>('ChatFile');
        this.chat_files.push(file);
        this.history.push(file);

        if (!this.isSelf(file)) {
            BaseChatService.play_sound(file, 'new-message');
        }

        this.sort();
    }

    downloadFile(item) {
        // todo: implement this if its used
    }

    protected chat_event(event: SocketEvent) {
        const chat_event = event.modelInstance<ChatEvent>('ChatEvent');
        this.chat_events.push(chat_event);
        this.history.push(chat_event);
        this.sort();
    }

    send() {
        if (!this.chat_input || this.chat_input.trim() == '') {
            this.chat_input = '';
            return;
        }

        this.sendEvent('chat.message', {
            'message': this.chat_input
        });

        this.chat_input = '';
    }

    private send_typing_event() {
        if (!this.last_typing_update) {
            this.last_typing_update = new Date()
        }

        let now = new Date();
        if (Number(now) - Number(this.last_typing_update) > 1000) {
            this.last_typing_update = new Date();
            this.sendEvent('chat.typing', {})
        }
    }

    clear_typing_events(user_identifier) {
        let to_remove = [];
        for (let item of this.users_typing) {
            if (item.user_identifier == user_identifier) {
                to_remove.push(item);
            }
        }
        for (let item of to_remove) {
            this.users_typing.splice(this.users_typing.indexOf(item), 1)
        }
    }

    person_is_typing(event: SocketEvent) {
        this.clear_typing_events(event.data.user_identifier);

        this.users_typing.push(event.data);

        setTimeout(() => {
            let index = this.users_typing.indexOf(event.data);
            if (index != -1) {
                this.users_typing.splice(this.users_typing.indexOf(event.data), 1);
            }
            this.trigger('data-changed');
            this.trigger('sync');
        }, 2000);

        this.trigger('data-changed');
    }


    get chat_input() {
        return this._chat_input;
    }

    set chat_input(v) {
        if (v != this._chat_input) {
            this.send_typing_event();
        }
        this._chat_input = v;
    }

    private sort() {
        let compare = (a, b) => {
            return a.date - b.date;
        };

        this.chat_lines.sort(compare);
        this.chat_events.sort(compare);
        this.chat_files.sort(compare);
        this.history.sort(compare);

        this.trigger('data-changed');
    }

    get chat_opened() {
        return this.chat_room && this.chat_room.opened;
    }

    leave_chat() {
        this.sendEvent('close-chat', {});
        this.offline_message_sent = true;
    }

    close_chat() {
        this.trigger('close-chat');
        this.chat_room = null;
        this.disconnect();
        this.socket = null;
        this.reset();
    }

    state_override;

    get state() {
        if (this.state_override) {
            return this.state_override;
        }

        if (this.disconnected && !this.connecting) {
            return ChatState.DISCONNECTED;
        }

        if (this.connecting) {
            return ChatState.LOADING;
        }

        // Only thing left should be connected but lets check for it anyway
        if (!this.connected) {
            return ChatState.LOADING;
        }

        // Socket opened but waiting on the server to assign us a chat room
        if (!this.chat_room) {
            return ChatState.LOADING;
        }

        if (this.offline_message_sent) {
            return ChatState.OFFLINE_MESSAGE_SENT;
        }

        // Chat room has been closed and the chat ended
        if (this.chat_room.closed) {
            return ChatState.CHAT_CLOSED;
        }

        if (this.attempting_to_close) {
            return ChatState.CLOSING;
        }

        if (this.out_of_office && !this.leaving_message) {
            return ChatState.OUT_OF_OFFICE;
        }

        // Chat room has not been opened by the customer yet, collecting info
        if (!this.chat_room.opened) {
            return ChatState.COLLECTING_INFO;
        }

        if (this.has_waited_too_long && !this.ok_waiting) {
            return ChatState.WAITING_TIMEOUT;
        }

        // Chat room has been opened but no customer service agent has been assigned
        if (this.chat_room.opened && !this.chat_room.customer_service_name) {
            if (!this.wait_timeout && !this.ok_waiting) {
                this.wait_timeout = setTimeout(() => {
                    this.has_waited_too_long = true;
                }, this.max_wait_time);
            }

            return ChatState.WAITING_FOR_REP;
        }

        // Chat has been opened and they are assigned to a rep
        return ChatState.IN_CHAT;
    }

    get chat_disabled() {
        return false;
    }

    isSelf(message) {
        if (Services.get<UserProfileService>('UserProfileService').user) {
            return message.user_identifier == `${Services.get<UserProfileService>('UserProfileService').user.id}`;
        }
        else {
            return message.user_identifier && `${message.user_identifier}`.indexOf('S-') != -1;
        }
    }
}