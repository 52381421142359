import VueItem from './VueItem';
import VueComponent from './VueComponent';

export default class VueMixin extends VueItem {
    parent: VueItem;
    constructor(parent) {
        super();
        this.parent = parent;
    }

    bindComponent(component) {
        this.component = component;
        this.setupDataBindings();
    }
}