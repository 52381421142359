import VueComponent, {prop} from '../../adapters/VueComponent';
import IVueComponent from '../../adapters/IVueComponent';

/*
    Why? Because vue is stupid.

    Vue doesn't allow text content to be used with v-text, ex: <span v-text="some_value">placeholder</span>
    Which would prevent issues with content layout shifts during js startup.
    So we have to make our own component to do this...
*/

class TextElementController extends VueComponent {
    @prop()
    value: string
}

export default function TextElement(): IVueComponent {
    return {
        tag: 'text-element',
        controller: TextElementController,
        template: '<span>{{ value }}</span>'
    }
}