export const html: string = `
<div class="dropdown generic-dropdown color-dropdown" v-dropdown>
    <button class="btn-base dropdown-label" type="button">
        <div class="flex-left-center spacing-sm no-wrap">
            <div class="color-circle circle no-shrink" :style="{'background': selected ? selected.background : noneColor, 'background-size': selected && selected.background ? 'cover' : null }"></div>
            {{ search ? '' : getSelectedName(selected) }}
        </div>
        <input type="text" 
            class="dropdown-search-input" 
            v-model="query" 
            :placeholder="getSelectedName(selected)"  
            @keydown.enter="autofillFromSelected($event)" 
            @keydown.up="tabUp($event)" 
            @keydown.down="tabDown($event)"
            v-if="search">
    </button>
    <ul class="dropdown-list shadow-lg flex-left column stretch no-wrap spacing-xs scrollbar" role="listbox" @keydown.up="tabUp($event)"  @keydown.down="tabDown($event)" @keydown.esc="close()" @keydown.tab="close()" @keydown.enter="close()" tabindex="0">
        <li class="flex-left-center spacing-sm" v-if="none && !hideNone" @click="select(null)" :class="{selected : isSelected(null)}" role="option">
            <div class="color-circle circle" :style="{'background': noneColor ? noneColor : 'white'}"></div>
            {{ getName(null, true) }}
        </li>
        
        <li class="flex-left-center spacing-sm" v-for="item in items" @click="select(item);" :class="{selected : isSelected(item)}" role="option">
            <div class="color-circle circle" :style="{'background': item.background, 'background-size': item.background ? 'cover' : null }"></div>
            {{ getName(item, true) }}
        </li>
    </ul>
</div>
`;
