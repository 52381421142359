/*
    Core.ts

    This file contains all of the core features that are loaded on the majority or all pages.
    The components must have all of their required models and services included in this file.

    All other functionality should be moved into its own file with a entry point set in the
    webpack.config.js file. You will need to include the generated javascript file using
    django and the additional_scripts block. This will reduce the size of the javascript files
    and loading time for the website.

    According to google:
    1-3 seconds load time increase the bounce rate probability by 32%
    1-5 seconds load time increase the bounce rate probability by 90%
    1-6 seconds load time increase the bounce rate probability by 106%
    1-10 seconds load time increase the bounce rate probability by 123%

    Google also uses the loading speed as a search result ranging signal.
 */

// Register sentry first so we can log errors that show up during the setup process
import {Services} from '../../ts/core/services/Services';
import registerCoreServices from '../../ts/core/services/init';
import registerChatServices from '../../ts/apps/chat/services/init';
import registerUserServices from '../../ts/apps/userprofile/services/init';

import registerUserModels from '../../ts/apps/userprofile/models/init';
import registerChatModels from '../../ts/apps/chat/models/init';

import VueApp from '../../ts/core/adapters/VueApp';

import registerChatComponents from '../../ts/apps/chat/components/init';
import registerCoreComponents from '../../ts/core/components/init';
import registerLoginComponents from '../../ts/apps/login/components/init';

import CarouselSliderApp from '../../ts/core/apps/CarouselSlider/CarouselSlider';

import DelayedSrcObserver from '../../ts/core/utils/DelayedSrcObserver';
import {RotatingBannerApp} from '../../ts/apps/marketing/components/RotatingBanner/RotatingBanner';

import registerCartComponents from '../../ts/apps/cart/components/init';
import BackendAlertApp from '../../ts/core/apps/BackendAlerts/BackendAlertApp';

import {Integration} from '../../ts/core/utils/Integration';
import {DropdownApp} from '../../ts/core/apps/Dropdown/DropdownApp';
import registerCartServices from '../../ts/apps/cart/services/init';
import registerCartModels from '../../ts/apps/cart/models/init';
import ApiResponseFormatter from '../../ts/core/formatters/APIResponseFormatter';
import ModelFormatter from '../../ts/core/formatters/ModelFormatter';
import PageSearch from '../../ts/apps/search/apps/PageSearch';
import {StorefrontRouteList} from '../../ts/core/services/StorefrontRouteList';
import {SubscribeApp} from '../../ts/apps/marketing/apps/SubscribeApp';


// Why is this not optional!!!!!
(window as any)['process'] = {
    env: {
        NODE_ENV: 'production'
    }
};

export default abstract class CoreIntegration extends Integration {

    override setupGlobalModules(): void {
        super.setupGlobalModules();
        DelayedSrcObserver();
    }

    setupModels(): void {
        // Models
        registerUserModels();
        registerChatModels();
    }

    setupServices(): void {
        window['Services'] = Services;

        Services.registerObject('RouteList', StorefrontRouteList);

        // Services
        registerCoreServices(Services);
        registerChatServices(Services);
        registerUserServices(Services);
    }

    override configureServices(): void {
        Services.resolve('loaded-core');
    }

    setupApps(): void {
        // ======= Chat App ========= \\
        VueApp.mount('#chat-app', (app) => {
            registerChatComponents(app);
            registerCoreComponents(app);
        });

        VueApp.mount('#login-app', (app) => {
            registerCoreComponents(app);
            registerLoginComponents(app);
        });

        CarouselSliderApp.mount('#carousel-app');
        DropdownApp.mount('#dropdown-app');
        RotatingBannerApp.mount('#rotating-banner-app');
        BackendAlertApp.mount('#backend-alerts-app');
        PageSearch.mount('#product-search-app');

        SubscribeApp.mount('#subscribe-app', (app) => {
            registerCoreComponents(app);
        });
    }
}

if (!window['devtoolsFormatters']) {
    window['devtoolsFormatters'] = [];
}
window['devtoolsFormatters'].push(ApiResponseFormatter);
window['devtoolsFormatters'].push(ModelFormatter)
