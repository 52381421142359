import VueComponent, {data, method} from '../../adapters/VueComponent';
import IVueComponent from '../../adapters/IVueComponent';
import {html} from './MeasurementOption.html';
import {MetricOptions} from '../../../apps/userprofile/models/User';
import {Services} from '../../services/Services';
import UserProfileService from '../../../apps/userprofile/services/UserProfileService';

class MeasurementOptionController extends VueComponent {

    @data()
    ups: UserProfileService;

    constructor(component) {
        super(component);
        this.ups = Services.get<UserProfileService>('UserProfileService');
    }

    @method()
    updateMetricOption() {
        this.ups.updateMetricOption();
    }
}

export default function MeasurementOption(): IVueComponent {
    return {
        tag: 'measurement-option',
        controller: MeasurementOptionController,
        template: html
    }
}