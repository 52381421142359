export const html:string = `
<div class="message-list" :class="containerStyling()">
    <div class="flex-left column stretch spacing-xs" v-if="hasMessages()">
        <div v-for="item in filtered_errors" class="flex-left-center no-wrap spacing-xs text-xs error-message">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 5.33331V7.99998M8 10.6666H8.00666M14.6667 7.99998C14.6667 11.6819 11.6819 14.6666 8 14.6666C4.3181 14.6666 1.33333 11.6819 1.33333 7.99998C1.33333 4.31808 4.3181 1.33331 8 1.33331C11.6819 1.33331 14.6667 4.31808 14.6667 7.99998Z" stroke="var(--error-weak, #F04438)" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {{ item }}
        </div>
        <div v-for="item in filtered_warnings" class="flex-left-center no-wrap spacing-xs text-xs warning-message">
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.99983 4.99997V7.66664M7.99983 10.3333H8.0065M7.07671 1.59445L1.59345 11.0655C1.28932 11.5909 1.13725 11.8535 1.15972 12.0691C1.17933 12.2571 1.27784 12.428 1.43075 12.5392C1.60605 12.6666 1.90956 12.6666 2.51658 12.6666H13.4831C14.0901 12.6666 14.3936 12.6666 14.5689 12.5392C14.7218 12.428 14.8203 12.2571 14.8399 12.0691C14.8624 11.8535 14.7103 11.5909 14.4062 11.0655L8.92295 1.59445C8.61991 1.07101 8.46838 0.809288 8.2707 0.721386C8.09826 0.644711 7.9014 0.644711 7.72897 0.721386C7.53128 0.809288 7.37976 1.07101 7.07671 1.59445Z" stroke="var(--warning-weak, #FEC84B)" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {{ item }}
        </div>
        <div v-for="item in filtered_successes" class="flex-left-center no-wrap spacing-xs text-xs success-message">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 7.99998L7 9.99998L11 5.99998M14.6667 7.99998C14.6667 11.6819 11.6819 14.6666 8 14.6666C4.3181 14.6666 1.33333 11.6819 1.33333 7.99998C1.33333 4.31808 4.3181 1.33331 8 1.33331C11.6819 1.33331 14.6667 4.31808 14.6667 7.99998Z" stroke="var(--success-weak, #75E0A7)" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {{ item }}
        </div>
        <div v-for="item in filtered_infos" class="flex-left-center no-wrap spacing-xs text-xs info-message">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 10.6666V7.99998M8 5.33331H8.00666M14.6667 7.99998C14.6667 11.6819 11.6819 14.6666 8 14.6666C4.3181 14.6666 1.33333 11.6819 1.33333 7.99998C1.33333 4.31808 4.3181 1.33331 8 1.33331C11.6819 1.33331 14.6667 4.31808 14.6667 7.99998Z" stroke="var(--info-weak, #84CAFF)" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {{ item }}
        </div>
    </div>
    <slot></slot>
</div>
`;
