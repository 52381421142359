import IVueDirective from '../adapters/IVueDirective';

export default function PasswordToggle(): IVueDirective {
    return {
        directive: {
            beforeMount: function (el, binding, vnode) {
                let input = el.querySelector('input');
                let toggle_button = el.querySelector('i.password-toggle')
                let on_click = () => {
                    let is_password = input.getAttribute('type') == 'password';
                    input.setAttribute('type', is_password ? 'text' : 'password');
                    toggle_button.classList.toggle('toggled');
                }
                toggle_button.addEventListener('click', on_click);
            },
        },
        tag: 'password-toggle'
    }
}
