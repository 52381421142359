// Same a v-toggle-password but works without vue

let initialized = false;
export default function PasswordToggleBinding() {
    if (initialized) {
        console.warn('Tried to initialize PasswordToggleBinding more than once.')
        return;
    }
    initialized = true;

    for (const el of document.querySelectorAll('[data-toggle-password]')) {
        let input = el.querySelector('input');
        let toggle_button = el.querySelector('i.password-toggle');
        let on_click = () => {
            let is_password = input.getAttribute('type') == 'password';
            input.setAttribute('type', is_password ? 'text' : 'password');
            toggle_button.classList.toggle('toggled');
        }
        toggle_button.addEventListener('click', on_click);
    }
}
