let initialized = false;
export default function ElementInViewBinding() {
    if (initialized) {
        console.warn('Tried to initialize ElementInViewBinding more than once.')
        return;
    }
    initialized = true;
    for (const el of document.querySelectorAll('[data-toggle-in-side-scroll-view]')) {
        let target = document.getElementById(el.attributes['data-toggle-in-side-scroll-view']?.value);
        if (target) {
            let check = () => {
                let parent_bounds = target.parentElement.getBoundingClientRect();
                let bounds = target.getBoundingClientRect();
                if (bounds.left >= parent_bounds.left - bounds.width/2 && bounds.right <= parent_bounds.right + bounds.width/2) {
                    el.classList.add('in-view');
                }
                else {
                    el.classList.remove('in-view');
                }
            }
            target.parentElement.addEventListener('scroll', check, {passive: true});

            // The check forces a reflow, wait until next frame to do it
            setTimeout(() => {
                check();
            })
        }
    }

    for (const el of document.querySelectorAll('[data-toggle-in-view]')) {
        let options = {
            root: null,
            rootMargin: '0px',
            threshold: 0
        }

        const observer = new IntersectionObserver((entry, observer) => {
            if (!entry || entry[0].isIntersecting) {
                el.classList.add('in-view');
            }
            else {
                el.classList.remove('in-view');
            }
        }, options);
        observer.observe(el);
    }
}