import {GenericDropdownController} from '../GenericDropdown/GenericDropdown';
import IVueComponent from '../../adapters/IVueComponent';
import {html} from './MultiSelectionDropdown.html';
import {data, method, prop} from '../../adapters/VueComponent';
import {Collection} from '../../models/Collection';
import {DropdownConfigType} from '../../directives/Dropdown';


class MultiSelectionDropdownController extends GenericDropdownController {
    @prop({
        emit:true
    })
    override selected: any[];

    @prop()
    all: boolean;

    @prop()
    any: boolean;

    @prop()
    idField: string; // Used for matching items back up when the items list changes

    @data()
    dropdown_config: DropdownConfigType;

    @prop()
    html: string;

    constructor(component) {
        super(component);

        this.dropdown_config  = {
            stay_open: true,
            on_close: () => {
                this.query = '';
            }
        }

        this.bind('change:items', () => {
            let item;

            if (!this.idField) {
                item = this.items.filter(v => this.selected.indexOf(v) != -1);
            }
            else {
                item = this.items.filter(v => this.selected.map(k => k[this.idField]).indexOf(v[this.idField]) != -1);
            }

            this.selected = item;
        });
    }

    @method()
    override isSelected(item: any) {
        if (this.any && item == null) {
            return this.selected.length == 0;
        }
        if (this.all && this.selected.length == this.items.length && this.selected.indexOf(this.none) == -1) {
            if (!(this.none && item == this.none)) {
                return true;
            }
        }
        if (this.none && item == this.none && this.selected.indexOf(item) != -1) {
            return true;
        }

        if (this.selected == null) {
            return false;
        }

        for (const value of this.selected) {
            if (this.isItemSelected(value, item)) {
                return true;
            }
        }

        return false;
    }

    @method()
    allSelected() {
        return this.selected?.length == this.items?.length && this.selected?.indexOf(this.none) == -1;
    }

    @method()
    override select(item: any): void {
        if (this.none && item == this.none) {
            if (this.selected.indexOf(item) == -1) {
                this.selected = [item];
            }
            else {
                this.selected.splice(this.selected.indexOf(item), 1);
            }

            if (this.triggerScope) {
                this.triggerFunction(item, this.triggerScope);
            }
            else {
                this.triggerFunction(item);
            }
            return;
        }

        if (this.none && item && item != this.none) {
            if (this.selected.indexOf(this.none) != -1) {
                this.selected.splice(this.selected.indexOf(this.none), 1);
            }
        }

        if (this.any && item == null) {
            this.selected = [];
        }

        if (item != null) {
            if (this.selected.indexOf(item) != -1) {
                this.selected.splice(this.selected.indexOf(item), 1);
            }
            else {
                this.selected.push(item)
            }
        }

        if (this.triggerFunction) {
            if (this.triggerScope) {
                this.triggerFunction(item, this.triggerScope);
            }
            else {
                this.triggerFunction(item);
            }
        }
    }

    @method()
    selectAll(): void {
        let item = this.selected.length == this.items.length ? [] : [...this.items];
        this.selected = item;

        if (this.triggerFunction) {
            if (this.triggerScope) {
                this.triggerFunction(item, this.triggerScope);
            }
            else {
                this.triggerFunction(item);
            }
        }
    }

    override getSelectedName(item: any) {
        if (this.selected.length == 0 && this.any) {
            return 'Any';
        }

        return this.selected.map(v => this.getName(v)).join(', ');
    }

    @method()
    override display(item) {
        if (!this.search || !this.query) {
            return true;
        }

        let name = super.getSelectedName(item);

        if (this.searchType == 'contains') {
            return name && name.toLowerCase().indexOf(this.query.toLowerCase()) != -1;
        }

        return name && name.toLowerCase().indexOf(this.query.toLowerCase()) === 0;
    }
}

export default function MultiSelectionDropdown(): IVueComponent {
    return {
        controller: MultiSelectionDropdownController,
        template: html,
        tag: 'multi-selection-dropdown'
    };
}
