import IVueDirective from '../adapters/IVueDirective';

export default function ToggleInSideScrollView(): IVueDirective {
    return {
        directive: {
            mounted: function (el, binding, vnode) {
                let target = document.getElementById(binding?.value);
                if (target) {
                    let check = () => {
                        let parent_bounds = target.parentElement.getBoundingClientRect();
                        let bounds = target.getBoundingClientRect();
                        if (bounds.left >= parent_bounds.left - bounds.width/2 && bounds.right <= parent_bounds.right + bounds.width/2) {
                            el.classList.add('in-view');
                        }
                        else {
                            el.classList.remove('in-view');
                        }
                    }
                    target.parentElement.addEventListener('scroll', check, {passive: true});
                    check();
                }
            },
        },
        tag: 'toggle-in-side-scroll-view'
    }
}
