import {modal} from '../Modal';

let initialized = false;
export default function ModalAttrBinding() {
    if (initialized) {
        console.warn('Tried to initialize ModalAttrBinding more than once.')
        return;
    }
    initialized = true;
    for (const item of document.querySelectorAll('[data-modal-action]')) {
        item.addEventListener('click', () => {
            if (item.attributes['data-modal-action']?.value) {
                if (item.attributes['data-modal-event']?.value) {
                    modal[item.attributes['data-modal-action'].value](item.attributes['data-modal-event']);
                }
                else {
                    modal[item.attributes['data-modal-action'].value]();
                }
            }
        })
    }
    for (const item of document.querySelectorAll('[data-modal]')) {
        item.addEventListener('click', () => {
            modal.open(item.attributes['data-modal'].value);
        });
    }
}

