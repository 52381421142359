import Chat from './Chat/Chat';
import ChatBubble from './ChatBubble/ChatBubble';
import ChatHeader from './ChatHeader/ChatHeader';
import ChatInputArea from './ChatInputArea/ChatInputArea';
import VueApp from '../../../core/adapters/VueApp';


export default function registerComponents(vue_app: VueApp) {
    // Add all chat components to core, its loaded on every page
    vue_app.registerComponent(Chat);
    vue_app.registerComponent(ChatBubble);
    vue_app.registerComponent(ChatHeader);
    vue_app.registerComponent(ChatInputArea);
}
