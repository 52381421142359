import VueApp from '../../../core/adapters/VueApp';
import {data, method} from '../../../core/adapters/VueComponent';
import MessageList from '../../../core/utils/MessageList';
import {Services} from '../../../core/services/Services';
import {Http} from '../../../core/services/Http';
import {processRequestError} from '../../../core/utils/utils';

export class SubscribeApp extends VueApp {
    @data()
    errors: MessageList = new MessageList();

    @data()
    successes: MessageList = new MessageList();

    @data()
    email: string;

    @data()
    processing: boolean;

    @method()
    subscribe(): void {
        this.errors = new MessageList();
        this.successes = new MessageList();

        if (!this.email || this.email.trim() == '') {
            this.errors.add('email', 'This field is required.')
            return;
        }

        this.processing = true;
        Services.get<Http>('$http').request({
            url: '/profile/api/request-subscription/',
            method: 'POST',
            data: {
                email: this.email
            }
        }).then((response) => {
            this.processing = false;
            if (response.data.message) {
                this.successes.add('email', response.data.message);
            }
            else {
                this.successes.add('email', 'A confirmation email has been sent to your inbox.')
            }
        }, (error) => {
            this.processing = false;
            this.errors = processRequestError(error);
        });
    }
}