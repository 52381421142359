import IVueComponent from '../../adapters/IVueComponent';
import {GenericDropdownController} from '../GenericDropdown/GenericDropdown';
import {prop} from '../../adapters/VueComponent';
import {html} from './OptionSet.html';


class OptionSetController extends GenericDropdownController {
    @prop()
    name: string;
}

export default function OptionSet(): IVueComponent {
    return {
        template: html,
        controller: OptionSetController,
        tag: 'option-set'
    }
}