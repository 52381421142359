import {html} from './ColorDropdown.html';
import {GenericDropdownController} from '../GenericDropdown/GenericDropdown';
import IVueComponent from '../../adapters/IVueComponent';
import {data, method, prop} from '../../adapters/VueComponent';

export class ColorDropdownController extends GenericDropdownController {

    @prop()
    noneColor;

    @prop()
    smallColorSquares;

    constructor(component) {
        super(component);
    }


    public override getName(item: any, short=false): string {
        if (!item && !this.noneColor) {
            return 'No Color Selected'
        }

        return super.getName(item, short);
    }
}

export default function ColorDropdown(): IVueComponent {
    return {
        controller: ColorDropdownController,
        template: html,
        tag: 'generic-color-dropdown'
    };
}
