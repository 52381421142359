let initialized = false;
export default function ScrollToBinding() {
    if (initialized) {
        console.warn('Tried to initialize ScrollToBinding more than once.')
        return;
    }
    initialized = true;

    for (const item of document.querySelectorAll('[data-scroll-to]')) {
        item.addEventListener('click', () => {
            if (item.attributes['data-scroll-to']?.value) {
                document.getElementById(item.attributes['data-scroll-to'].value)?.scrollIntoView({behavior: 'smooth', block: 'start'})
            }
        })
    }
    for (const item of document.querySelectorAll('[data-inner-scroll-to]')) {
        item.addEventListener('click', () => {
            if (item.attributes['data-inner-scroll-to']?.value) {
                let el = document.getElementById(item.attributes['data-inner-scroll-to'].value);

                if (!el.parentElement) {
                    return;
                }

                // Gap seems to be ignored by offsetLeft so we need to subtract it
                let left_offset = el.offsetLeft;
                let gap = el.parentElement.computedStyleMap().get('gap');
                if (gap) {
                    left_offset -= parseInt(gap.toString());
                }

                el?.parentElement?.scroll({left: left_offset, behavior: "smooth"})
            }
        })
    }
}
