import {html} from './GenericDropdown.html';
import VueComponent, {computed, data, method, prop} from '../../adapters/VueComponent';
import IVueComponent from '../../adapters/IVueComponent';
import {mobileScreenSize} from '../../utils/utils';
import MobileMixin from '../../mixins/MobileMixin';

export class GenericDropdownController extends VueComponent {
    static override mixins = [MobileMixin]

    @prop({
        watch: true
    })
    items: any[];

    @prop({
        emit: true,
        watch: true
    })
    selected: any;

    @prop()
    none: string;

    @prop()
    triggerFunction: (item: any, trigger_scope?: any) => void;

    @prop()
    triggerScope: any;

    @prop()
    hideNone: boolean;

    @prop()
    placeholder: string;

    @prop()
    nameField: string;

    @prop()
    field: any;

    @prop()
    nameFunction: (any) => string;

    @prop()
    search: boolean;

    @data()
    query: string;

    @data()
    noneLast: boolean;

    @prop()
    nameFromItems: boolean;

    @prop()
    searchType: string;

    @prop()
    autocomplete: string;

    @data()
    autocomplete_value: string;

    @data()
    selection_index: number;
    
    constructor(component) {
        super(component);

        // For some reason v-model with <select> causes an infinite loop.
        // The only way around this that I could find was to track with the index and get the element from the list.
        this.bind('change:selected', this.setSelectionIndex.bind(this));
        this.bind('change:items', this.setSelectionIndex.bind(this));
    }

    setSelectionIndex() {
        if (this.items) {
            if (this.field !== undefined) {
                this.selection_index = this.items.indexOf(this.items.find(v => v[this.field] == this.selected));
            }
            else {
                this.selection_index = this.items.indexOf(this.selected);
            }
        }
    }

    override mounted() {
        super.mounted();
        this.setSelectionIndex();
    }

    @method()
    anyVisible() {
        if (!this.search || !this.query) {
            return true;
        }

        return !!this.items.find(v => this.display(v));
    }

    @method()
    display(item) {
        if (!this.search || !this.query) {
            return true;
        }

        let name = this.getSelectedName(item);

        if (this.searchType == 'contains') {
            return name && name.toLowerCase().indexOf(this.query.toLowerCase()) != -1;
        }
        else {
            return name && name.toLowerCase().indexOf(this.query.toLowerCase()) === 0;
        }
    }

    get nameFieldOrDefault(): string {
        return this.nameField || 'name';
    }

    @method()
    public getSelectedName(item: any) {
        if (this.selected == null && this.items && this.field) {
            for (const value of this.items) {
                if (value[this.field] == null) {
                    return this.getName(value);
                }
            }
        }

        if (this.selected != null && this.items) {
            for (const value of this.items) {
                // If both types are strings then ignore case
                if (typeof value[this.field] == "string" && typeof item == "string") {
                    if (value[this.field].toLowerCase() === item.toLowerCase()) {
                        return this.getName(value);
                    }
                }
                else {
                    if (value[this.field] === item) {
                        return this.getName(value);
                    }
                }
            }
        }

        return this.getName(item);
    }

    @method()
    public getName(item: any, short=false): string {
        if (item == null) {
            return this.none || this.placeholder || 'None';
        }

        if (this.nameFunction && typeof this.nameFunction == 'function') {
            return this.nameFunction(item);
        }

        if (this.nameFromItems && typeof item != 'object') {
            for (const i of this.items) {
                if (i.value == item) {
                    item = i;
                }
            }
        }

        const type = typeof item;
        switch (type) {
            case 'object':
                if (short && !this.nameField && item['short_name']) {
                    return item['short_name'];
                }
                else {
                    return item[this.nameFieldOrDefault];
                }

            case 'number':
                return item + '';

            case 'string':
                return item;

            default:
                return item;
        }
    }

    @method()
    public select(item: any): void {
        if (this.field == null) {
            this.selected = item;
        }
        else {
            this.selected = item[this.field];
        }

        if (this.triggerFunction) {
            if (this.triggerScope) {
                this.triggerFunction(item, this.triggerScope);
            }
            else {
                this.triggerFunction(item);
            }
        }

        this.query = '';
    }

    @method()
    public isSelected(item: any): boolean {
        return this.isItemSelected(this.selected, item);
    }

    isItemSelected(selected, item) {
        if (!selected)
            return false;

        if (selected.equals) {
            return selected.equals(item);
        }
        else if (this.field == null || !item) {
            return selected === item;
        }
        else if (selected[this.field]) {
            return selected[this.field] === item[this.field];
        }
        else {
            return selected === item[this.field];
        }
    }

    @method()
    focusFirstListElement($event) {
        $event.preventDefault();

        let list_element = this.$el.parentElement.querySelector('ul');
        if (list_element && list_element.children.length > 0) {
            let next = list_element.children[0];
            while (next) {
                if (next.style.display == 'none') {
                    next = next.nextElementSibling;
                }
                else {
                    break;
                }
            }

            next?.focus({
                preventScroll: true
            });
        }
    }

    @method()
    tabUp($event) {
        $event.preventDefault();
        if (this.selection_index > 0) {
            this.select(this.items[this.selection_index - 1]);
        }
    }

    @method()
    tabDown($event) {
        $event.preventDefault();
        if (this.selection_index < this.items.length - 1) {
            this.select(this.items[this.selection_index + 1]);
        }
    }

    @method()
    close() {
        // This should normally be taken care of by the v-dropdown but in case we need to close it with
        // other accessibility functions
        if (this.dropdownRoot()?.classList.contains('open')) {
            this.dropdownRoot()?.classList.remove('open');
        }
    }

    @method()
    open() {
        if (!this.dropdownRoot()?.classList.contains('open')) {
            this.dropdownRoot()?.classList.add('open');
        }
    }

    dropdownRoot() {
        return this.$el.parentElement.querySelector('.generic-dropdown');
    }

    @method()
    autofillFromSelected($event) {
        $event.preventDefault();

        if (!this.query) {
            return;
        }

        for (const item of this.items) {
            if (this.display(item)) {
                this.select(item);
                this.close();
                return;
            }
        }
    }

    @method()
    checkAutocomplete($event) {
        // This is automatically filled in by the browser and will not match the selection list.
        // We have to use z-input + position absolute to hide the element because the browser will
        // not autocomplete display none inputs.

        if (!this.autocomplete_value) {
            return;
        }

        for (const item of this.items) {
            let name = this.getName(item);
            if (name) {
                if (name.toLowerCase() == this.autocomplete_value.toLowerCase()) {
                    this.select(item);
                    return;
                }
            }
        }
    }
}

export default function GenericDropdown(): IVueComponent {
    return {
        controller: GenericDropdownController,
        template: html,
        tag: 'generic-dropdown'
    };
}
