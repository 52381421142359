import {html} from './Chat.html';
import ChatService from '../../services/ChatService';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import VueComponent, {computed, data, method, prop} from '../../../../core/adapters/VueComponent';
import Company from '../../../../core/services/Company';
import {eventToFiles, getInnerHeight} from '../../../../core/utils/utils';
import {Services} from '../../../../core/services/Services';
import UserProfileService from '../../../userprofile/services/UserProfileService';

export class ChatController extends VueComponent {

    @data()
    chat_input: string;

    @prop()
    chat: ChatService;

    @data()
    company: Company;

    @data()
    email: string = '';

    @data()
    username: string = '';

    @data()
    description: string = '';

    constructor(component) {
        super(component);

        this.company = Services.get<Company>('Company');
        window['chat'] = this.chat;
    }

    override mounted() {
        super.mounted();

        this.chat.bind('data-changed', () => {
            this.$forceUpdate();

            let scroll_area = this.$el.querySelector('#chat-scroll-area');

            if (!scroll_area) {
                return;
            }

            // 15px buffer
            let at_bottom = (scroll_area.scrollHeight - scroll_area.scrollTop) - 15 <= getInnerHeight(scroll_area);

            if (at_bottom) {
                setTimeout(() => {
                    let scroll_area = this.$el.querySelector('#chat-scroll-area');
                    scroll_area.scrollTop = scroll_area.scrollHeight + scroll_area.scrollTop;
                });
            }
        });

        let scroll_area = this.$el.querySelector('#chat-scroll-area');
        if (scroll_area) {
            scroll_area.scrollTop = scroll_area.scrollHeight + scroll_area.scrollTop;
        }
    }

    @method()
    canGroup(message, index) {
        if (!index) {
            return false;
        }

        const last = this.chat.history[index - 1];

        let minutes_between = (Math.abs(Number(last.date) - Number(message.date)) / 1000 / 60);

        return last.user_identifier == message.user_identifier && minutes_between < 10;
    }

    @method()
    isSelf(message) {
        if (Services.get<UserProfileService>('UserProfileService').user) {
            return message.user_identifier == `${Services.get<UserProfileService>('UserProfileService').user.id}`;
        }
        else {
            return message.user_identifier && `${message.user_identifier}`.indexOf('S-') != -1;
        }
    }

    @method()
    drop(event) {
        let files = eventToFiles(event);
        this.chat.setupFile(files[0]);
    }
}

export default function ChatComponent(): IVueComponent {
    return {
        controller: ChatController,
        template: html,
        tag: 'chat-component'
    };
}
