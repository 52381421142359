export const html: string = `
<div class="dropdown generic-dropdown desktop-only" v-if="!mobile" v-dropdown>
    <button class="btn-base dropdown-label" type="button">
        {{ search ? '' : getSelectedName(selected) }}
        
        <input type="text" 
            class="dropdown-search-input" 
            v-model="query" 
            :placeholder="getSelectedName(selected)"
            @keydown.down="focusFirstListElement($event)"  
            @keydown.enter="autofillFromSelected($event)" 
            @click="open()"
            v-if="search">
           
        <input type="text" 
            class="autocomplete-element-handler" 
            v-model="autocomplete_value"  
            :autocomplete="autocomplete" 
            v-if="autocomplete" 
            aria-hidden="true"
            @change="checkAutocomplete($event)">
    </button>
    <ul class="dropdown-list shadow-lg flex-left column no-wrap stretch spacing-xs scrollbar" role="listbox" @keydown.up="tabUp($event)"  @keydown.down="tabDown($event)" @keydown.esc="close()" @keydown.tab="close()" @keydown.enter="close()" tabindex="0">
        <li v-if="none != null && !hideNone && !noneLast" @click="select(null)" :class="{'selected' : isSelected(null)}" role="option" aria-label="Select a value">
            {{ getName(null) }}
        </li>
        <li v-for="item in items"   
            role="option"  
            :class="{'selected' : isSelected(item)}"
            @click="select(item)"
            @keydown.enter="select(item); close();" 
            @keydown.up="tabUp($event)" 
            @keydown.down="tabDown($event)" 
            v-show="display(item)"
            :aria-selected="isSelected(item) ? 'true' : 'false'">
            {{ getName(item) }}
        </li>
        <li v-if="none != null && !hideNone && noneLast" @click="select(null)" :class="{'selected' : isSelected(null)}" role="option">
            {{ getName(null) }}
        </li>
        <li v-show="items && items.length > 0 && !anyVisible()" class="no-results-warning">
            No Results Found
        </li>
    </ul>
</div>
<select v-model="selection_index" @change="select(items[selection_index])" class="mobile-only" v-if="mobile">
    <option v-if="none != null && !hideNone && !noneLast" :value="null">
        {{ getName(null) }}
    </option>
    <option :value="index" v-for="(item, index) in items">
        {{ getName(item) }}
    </option>
    <option :value="undefined" v-show="items && items.length > 0 && !anyVisible()">
        No Results Founds
    </option>
</select>
`;
