export const html:string = `
<div class="modal" id="loginModal">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body clearfix">
                <button type="button" class="close" @click="close()" aria-label="Close">
                    &times;
                </button>
                <account-login v-if="isOpen" :modal="true"></account-login>
            </div>
        </div>
    </div>
</div>
`;