import IVueDirective from '../adapters/IVueDirective';

export default function InnerScrollTo(): IVueDirective {
    return {
        directive: {
            mounted: function (el, binding, vnode) {
                el.addEventListener('click', () => {
                    if (binding?.value) {
                        let el = document.getElementById(binding?.value);
                        if (!el.parentElement) {
                            return;
                        }

                        // Gap seems to be ignored by offsetLeft so we need to subtract it
                        let left_offset = el.offsetLeft;
                        let gap = el.parentElement.computedStyleMap().get('gap');
                        if (gap) {
                            left_offset -= parseInt(gap.toString());
                        }

                        el?.parentElement?.scroll({left: left_offset, behavior: "smooth"})
                    }
                })
            },
        },
        tag: 'inner-scroll-to'
    }
}
