import {MetricOptions} from '../../../apps/userprofile/models/User';

export const html:string = `
<fieldset class="input-group single-option">
    <div class="toggle-group toggle-sm">
        <label for="imperial">
            <input id="imperial" value="${MetricOptions.IMPERIAL}" name="measurement" type="radio" :checked="ups.metric_option == ${MetricOptions.IMPERIAL}" v-model="ups.metric_option" @change="ups.updateMetricOption()"/>
            IN
        </label>
        <label for="metric">
            <input id="metric" value="${MetricOptions.METRIC}" name="measurement" type="radio" :checked="ups.metric_option == ${MetricOptions.METRIC}" v-model="ups.metric_option" @change="ups.updateMetricOption()"/>
            CM
        </label>
    </div>
</fieldset>
`;