import MessageList from '../../utils/MessageList';
import {html} from './MessageList.html';
import VueComponent, {computed, method, prop} from '../../adapters/VueComponent';
import IVueComponent from '../../adapters/IVueComponent';
import {IPromise} from '../../utils/SimplePromise';

export class MessageListController extends VueComponent {
    // Fixes issues with the <slot> component not syncing correctly. See note in parent class.
    override SLOT_SYNC = true;

    @prop()
    errors: MessageList;

    @prop()
    warnings: MessageList;

    @prop()
    successes: MessageList;

    @prop()
    infos: MessageList;

    @prop()
    field: string;

    @computed()
    filtered_errors(): string[] {
        return this.filteredItems(this.errors);
    }

    @computed()
    filtered_warnings(): string[] {
        return this.filteredItems(this.warnings);
    }

    @computed()
    filtered_successes(): string[] {
        return this.filteredItems(this.successes);
    }

    @computed()
    filtered_infos(): string[] {
        return this.filteredItems(this.infos);
    }

    @method()
    hasMessages() {
        return this.filtered_errors().length > 0 || this.filtered_warnings().length > 0 || this.filtered_successes().length > 0 || this.filtered_infos().length > 0;
    }

    @method()
    containerStyling() {
        if (this.errors && this.filtered_errors().length > 0) {
            return {
                'error': true
            }
        }
        if (this.warnings && this.filtered_warnings().length > 0) {
            return {
                'warning': true
            }
        }
        if (this.successes && this.filtered_successes().length > 0) {
            return {
                'success': true
            }
        }
        if (this.infos && this.filtered_infos().length > 0) {
            return {
                'info': true
            }
        }
    }

    keys(): string[] {
        if (this.field) {
            return String(this.field).split(',')
        }
        return [];
    }

    filteredItems(items: MessageList) {
        if (!items) {
            return [];
        }

        if (!this.field) {
            console.warn(`Error list field is not defined`);
            return [];
        }

        if (!(items instanceof MessageList)) {
            // A MessageList object should be used for error message handling.
            // There is some old code somewhere that isn't using it so lets try to convert it to the correct type.
            items = new MessageList(items);
        }

        let merged = []
        for (const field of this.keys()) {
            merged = [...new Set([...merged, ...(items.get(field) || [])])]
        }

        return merged
    }
}

export default function MessageListComponent(): IVueComponent {
    return {
        controller: MessageListController,
        template: html,
        tag: 'message-list'
    };
}
