export const html:string = `
<div class="file-upload-zone" @dragover.prevent="" @drop.prevent="drop">
    <img :src="$filters.static('img/icons/upload-cloud.svg')" alt="Upload File">
    <p class="desktop-only"> Drag file here to upload <br> or </p>
    <div class="input-group">
        <input class="hidden" :id="uuid" type="file" @change="drop">
        <label class="btn-base btn-primary-action" :for="uuid" v-show="!uploading">Choose File</label>
        <span class="text-sm">(eps, ai, pdf, psd, tif, bmp, png, jpg, gif, heif or avif)</span>
        <span v-if="file">{{ file.name }}</span>
        <progress v-if="uploading" id="file" max="100" :value="percent_loaded">{{ percent_loaded }}</progress>
    </div>
</div>
`;