import {IServices} from './Services';
import Company from './Company';
import ModelSyncService from './ModelSyncService';
import CookieManager from './CookieManager';
import {Http} from './Http';
import RouteService from './RouteService';
import NamedRouteService from './NamedRouteService';
import {Analytics} from './Analytics';
import Debug from './Debug';
import {HttpUpload} from './HttpUpload';
import BasicStorageManager from './BasicStorageManager';
import {LocalStorage} from './LocalStorage';

export default function registerServices(services: IServices) {
    // Register core services and adapters
    services.singleton('$http', Http);
    services.singleton('$upload', HttpUpload)
    if (navigator.cookieEnabled && CookieManager.enabled()) {
        services.registerClass('$cookies', CookieManager);
    }
    else {
        // A lot more will be broken than just the javascript due to CSRF rules but lets do this anyway for good measure
        console.warn('Cookies are not enabled. This may cause unexpected issues.')
        services.singleton('$cookies', BasicStorageManager);
    }
    services.singleton('Company', Company);
    services.singleton('ModelSyncService', ModelSyncService);
    services.singleton('RouteService', RouteService)
    services.singleton('$NamedRouteService', NamedRouteService);
    services.singleton('Analytics', Analytics);
    services.singleton('Debug', Debug);
    services.singleton('LocalStorage', LocalStorage);
}