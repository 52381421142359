export const html: string = `
<nav v-show="pages && pages.length > 1 && isValid()" class="pagination toggle-group no-print">
    <a class="flat-link spacing-xs" :href="previous.url" aria-label="Previous Page" v-if="showPrevious">
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3333 9.99984H4.66667M4.66667 9.99984L10.5 15.8332M4.66667 9.99984L10.5 4.1665"
                  stroke="var(--text-strong, #101828)" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span class="desktop-only" v-if="!small">Previous</span>
    </a>
    
    <a class="flat-link" v-for="p in pages" :class="{active: page == p.page}" :href="p.url">{{ p.page }}</a>
    
    <a class="flat-link spacing-xs" :href="next.url" aria-label="Next Page" v-if="showNext">
        <span class="desktop-only" v-if="!small">Next</span>
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66667 9.99984H16.3333M16.3333 9.99984L10.5 4.1665M16.3333 9.99984L10.5 15.8332"
                  stroke="var(--text-strong, #101828)" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </a>
</nav>
`;
