export const html:string = `

<nav class="pagination toggle-group no-print" v-if="totalPages > 1">
    <a v-if="page > 1" @click="changePage(page - 1)" aria-label="Previous Page" class="flat-link spacing-xs">
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3333 9.99984H4.66667M4.66667 9.99984L10.5 15.8332M4.66667 9.99984L10.5 4.1665"
                  stroke="var(--text-strong, #101828)" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span class="desktop-only" v-if="!small">Previous</span>
    </a>
    
    <a v-for="p in pages" class="flat-link" :class="{selected: p == page}" @click="changePage(p)">{{ p }}</a>
    
    <a v-if="page < totalPages" @click="changePage(page + 1)" aria-label="Next Page" class="flat-link spacing-xs">
        <span class="desktop-only" v-if="!small">Next</span>
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66667 9.99984H16.3333M16.3333 9.99984L10.5 4.1665M16.3333 9.99984L10.5 15.8332"
                  stroke="var(--text-strong, #101828)" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </a>
</nav>
`;
