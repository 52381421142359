import VueComponent, {data, method, prop} from '../../adapters/VueComponent';
import {eventToFiles} from '../../utils/utils';
import {html} from './FileUpload.html';
import {uuid4} from '../../utils/uuid';
import {Services} from '../../services/Services';
import {Http} from '../../services/Http';


class FileUploadController extends VueComponent {

    @prop()
    api: string;

    @prop()
    success: (response: any) => {};

    @prop()
    failure: (error: any) => {};

    @data()
    file: File;

    @data()
    uuid;

    @data()
    percent_loaded: number;

    @data()
    uploading: boolean;

    constructor(component) {
        super(component);
        this.uuid = uuid4();
    }

    @method()
    drop($event) {
        let files = eventToFiles($event);
        if (files.length > 0) {
            this.file = files[0];
            this.upload();
        }
    }

    @method()
    upload() {
        let form = new FormData();
        form.append('file', this.file);

        Services.get<Http>('$http').request({
            url: '/custom/api/v1/upload/',
            data: form,
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                if (progressEvent.total) {
                    this.percent_loaded = (progressEvent.loaded / progressEvent.total) * 100;
                }
                else {
                    this.percent_loaded = 0
                }
            }
        }).then((response) => {
            this.uploading = false;
            if (this.success) {
                this.success(response);
            }
        }, (error) => {
            this.uploading = false;
            if (this.failure) {
                this.failure(error);
            }
        });
    }
}


export default function FileUpload() {
    return {
        tag: 'file-upload',
        template: html,
        controller: FileUploadController
    }
}