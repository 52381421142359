import VueComponent, {data, method, prop} from '../../adapters/VueComponent';
import IVueComponent from '../../adapters/IVueComponent';
import {html} from './Size.html';
import {Services} from '../../services/Services';
import UserProfileService from '../../../apps/userprofile/services/UserProfileService';
import {clamp} from '../../utils/utils';

class SizeController extends VueComponent {
    // All sizes are assumed to be in inches and converted to metric based on the user preference

    @data()
    ups: UserProfileService;

    @prop()
    value: number;

    @prop()
    area: boolean;

    constructor(component) {
        super(component);

        // Needed to sync measurement option changes
        this.ups = Services.get('UserProfileService');
    }

    @method()
    convertedValue() {
        let ups = Services.get<UserProfileService>('UserProfileService');
        let converted = this.value * ups.conversion_ratio * ups.conversion_ratio;
        return `${clamp(converted)} sq. ${ups.size_label}`;
    }
}

export default function Size(): IVueComponent {
    return {
        tag: 'size',
        controller: SizeController,
        template: html
    }
}