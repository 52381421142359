
export interface IMessageHash {
    [key: string]: string[];
}

export default class MessageList {
    items: IMessageHash;

    constructor(messages?: IMessageHash) {
        this.reset();

        if (messages) {
            this.merge(messages);
        }
    }

    reset(): void {
        this.items = Object.create(null);
    }

    add(field: string, errors: string[] | string): void {
        this.merge({[field]: typeof errors == 'string' ? [errors] : errors});
    }

    remove(field: string): void {
        delete this.items[field];
    }

    get(field: string): string[] {
        return this.items[field];
    }

    merge(messages: IMessageHash|MessageList): void {
        if (!messages) {
            return;
        }

        if (messages instanceof MessageList) {
            messages = messages.list;
        }

        for (const key of Object.keys(messages)) {
            if (!messages[key]) {
                continue;
            }

            // Just in case the input wasn't correct
            if (typeof messages[key] == 'string') {
                this.items[key] = [...new Set([...(this.items[key] || []), `${messages[key]}`.trim()])];
            }
            else {
                this.items[key] = [...new Set([...(this.items[key] || []), ...messages[key].filter(v => v).map(v => `${v}`.trim())])];
            }

            // Remove empty sets
            if (this.items[key].length == 0) {
                delete this.items[key];
            }
        }
    }

    get list(): IMessageHash {
        return this.items;
    }

    get flatList(): string[] {
        return this.keys.map(key => `${key}:${this.items[key].join(',')}`)
    }

    get keys(): string[] {
        return Object.keys(this.items);
    }

    get length(): number {
        return this.keys.length;
    }

    contains(key): boolean {
        key = String(key);
        return !!(this.items[key] && this.items[key].length > 0);
    }

    containsKeys(keys: string[]) {
        for (const key of keys) {
            if (this.contains(key)) {
                return true;
            }
        }

        return false;
    }
}
