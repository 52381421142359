import {field, Field} from '../../../core/models/fields/Field';
import {Model} from '../../../core/models/Model';
import {Services} from '../../../core/services/Services';
import {Http} from '../../../core/services/Http';
import {APIResource} from '../../../core/models/APIResource';

export class UserAddress extends Model {
    public static override uri: string = '/profile/api/user/address/:id/';
    static override objects: APIResource<UserAddress>;

    @field()
    archived: boolean;

    @field(Field, {
        required: true
    })
    city: string;

    @field()
    city_area:string;

    @field()
    company_name: string;

    @field(Field, {
        required: true
    })
    country: string;

    @field(Field, {
        required: false
    })
    country_area: string;

    @field()
    default_billing: boolean;

    @field()
    default_shipping: boolean;

    @field(Field, {
        required: true
    })
    first_name: string;

    @field(Field, {
        required: true
    })
    last_name: string;

    @field(Field, {
        required: true
    })
    phone: string;

    @field(Field, {
        required: true
    })
    postal_code: string;

    @field(Field, {
        required: true
    })
    street_address_1: string;

    @field()
    street_address_2: string;

    @field()
    parent: string;

    @field()
    po_box: boolean;

    _constructor() {
        if (!this.country)
            this.country = 'US';
    }

    get label(): string {
        return `${this.first_name} ${this.last_name} ${this.street_address_1} ${this.street_address_2}, ${this.city}, ${this.country_area} ${this.postal_code}`;
    }

    get fullName() {
        return `${this.first_name} ${this.last_name}`;
    }

    verify() {
        return Services.get<Http>('$http').request({
            method: 'POST',
            data: this.getPostData(),
            url: '/profile/api/verify-address/'
        });
    }

    // Deprecated
    useVerifiedAddressCallback(callback?: (address: any) => void): void {
        Services.get<Http>('$http').request({
            method: 'POST',
            data: {
                address_id: this.id
            },
            url: '/profile/api/use-verified-address/'
        }).then((response: any) => {
            this.reload(callback);
        }, (err: any) => {
            callback(err);

            return err;
        });
    }
    useVerifiedAddress() {
        return Services.get<Http>('$http').request({
            method: 'POST',
            data: {
                address_id: this.id
            },
            url: '/profile/api/use-verified-address/'
        });
    }

    override validate() {
        super.validate();

        if (!this.city || this.city == '') {
            this._errors.add('city', 'For APO addresses use "APO".');
        }

        if(this.country == 'US' && !this.country_area) {
            this._errors.add('country_area', 'This field is required.');
        }
        else if(this.errors) {
            this._errors.remove('country_area');
        }

        return this._errors;
    }
}
